import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useAboutUsHeadingSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageRo, wpPageEn } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page About Us" } }
        language: { code: { eq: RO } }
      ) {
        aboutUsFields {
          aboutUsHeadingSection {
            headerTitle
            headerContent
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page About Us" } }
        language: { code: { eq: EN } }
      ) {
        aboutUsFields {
          aboutUsHeadingSection {
            headerTitle
            headerContent
          }
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    pages[language]?.aboutUsFields?.aboutUsHeadingSection ||
    wpPageRo?.aboutUsFields?.aboutUsHeadingSection
  )
}
