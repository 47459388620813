import React from "react"
import { useAboutUsHeadingSection } from "../../hooks/AboutUsPage/useAboutUsHeadingSection"
import "./AboutUsHeaderSection.scss"

const AboutUsHeaderSection = () => {
  const { headerTitle, headerContent } = useAboutUsHeadingSection()

  return (
    <div className="aboutUsHeaderSection">
      <div className="container">
        {headerTitle && <h1>{headerTitle}</h1>}
        {headerContent && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: headerContent,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default AboutUsHeaderSection
