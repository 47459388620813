import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import AboutUsHeaderSection from "../components/AboutUsHeaderSection/AboutUsHeaderSection"
import AboutUsSubHeaderSection from "../components/AboutUsSubHeaderSection/AboutUsSubHeaderSection"
import { useTranslation } from "react-i18next"
import Loadable from "@loadable/component"
import ClipLoader from "react-spinners/ClipLoader";
const withSpinner = (importComponent) => Loadable(importComponent, {
  fallback: <ClipLoader color="#2e41ff" size={150} cssOverride={{ display: 'block', margin: '50px auto', borderWidth: '4px' }} aria-label="Loading Spinner" />,
});
const AboutUsServiceSection = withSpinner(() => import("../components/AboutUsServiceSection/AboutUsServiceSection"));
const AboutUsInfoSection = withSpinner(() => import("../components/AboutUsInfoSection/AboutUsInfoSection"));
const FaqPageSection = withSpinner(() => import("../components/FaqPageSection/FaqPageSection"));
const Footer = withSpinner(() => import("../components/Footer/Footer"));

const AboutUs = () => {
  const { i18n } = useTranslation()
  const { language } = i18n

  return (
    <Layout>
      <Header />
      <AboutUsHeaderSection />
      <AboutUsSubHeaderSection />
      <AboutUsServiceSection />
      <AboutUsInfoSection />
      <FaqPageSection />
      <Footer />
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default AboutUs
