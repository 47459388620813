import React from "react"
import { useAboutUsSubHeadingSection } from "../../hooks/AboutUsPage/useAboutUsSubHeadingSection"
import "./AboutUsSubHeaderSection.scss"

const AboutUsSubHeaderSection = () => {
  const content = useAboutUsSubHeadingSection()
  return (
    <div className="aboutUsSubHeaderSection">
      <div className="container">
        {content && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default AboutUsSubHeaderSection
